export function reccomenderFunctions() {
  // recommender 'collect'
  const coll = document.querySelectorAll('.recc');

  for (let i = 0; i < coll.length; i++) {
    coll[i].addEventListener('click', function () {
      console.table([this.dataset.reccPart, this.dataset.reccLoad]);
      collector.collect(this.dataset.reccPart, this.dataset.reccLoad);
    });
  }

  // recommender 'collectSubmit'
  const collSub = document.querySelectorAll('.recc__submit');
  for (let i = 0; i < collSub.length; i++) {
    collSub[i].addEventListener('submit', function (event) {
      event.preventDefault();
      console.table([this.dataset.reccFunction, this.dataset.reccPart, this.dataset.reccLoad]);
      collector.collectSubmit(this.dataset.reccFunction, this.dataset.reccPart, this.dataset.reccLoad);
    });
  }
}